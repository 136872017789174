<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              v-if="props.creditCard"
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <ElFormItem
                prop="credit_card_uuid"
                :label="$t('credit_card')"
              >
                <el-select-v2
                  :key="getCreditCardKey()"
                  v-loading="getCreditCardKey() === 0"
                  v-model="form.credit_card_uuid"
                  :remote-method="searchCreditCards"
                  :placeholder="$t('select_a_credit_card')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCreditCards()"
                  style="width: 100%"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="amount" :label="$t('amount')">
                <ElInputNumber
                  v-model="form.amount"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="currency" :label="$t('currency')">
                <ElSelect
                  v-model="form.currency"
                  :autocomplete="true"
                  :filterable="true"
                  style="width: 100%"
                  :placeholder="$t('select_currency')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="status" :label="$t('status')">
                <ElSelect
                  v-model="form.status"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="provider" :label="$t('provider')">
                <ElInput
                  v-model="form.provider"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="provider_number" :label="$t('provider_number')">
                <ElInput
                  v-model="form.provider_number"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewCreditCardDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_credit_card') }}
        </span>
      </template>
      <QuickCreateCreditCardDialog
        @create=";(showNewCreditCardDialog = false), createCreditCard($event)"
        @cancel="showNewCreditCardDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_CREDIT_CARD_PAYMENT from '@/graphql/credit-card-payments/mutation/createCreditCardPayment.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  creditCard: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  credit_card_uuid: '',
  amount: 0,
  status: '',
  provider: '',
  provider_number: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewCreditCardDialog = ref(false)
const statuses = getCreditCardPaymentStatus()
const currencies = getCurrencies()

const rules = {
  amount: { required }
}

onMounted(() => {
  initialCreditCards()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  amount: [
    {
      required: true,
      message: t('amount_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid || !props.creditCard?.uuid || !form.credit_card_uuid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_CREDIT_CARD_PAYMENT, {
    amount: form.amount ? parseInt(form.amount * 100) : 0,
    currency: form.currency,
    credit_card_uuid: props.creditCard?.uuid ?? form.credit_card_uuid,
    status: form.status,
    provider: form.provider,
    provider_number: form.provider_number
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createCreditCardPayment ?? null)
    loading.value = false
  }
}

const createCreditCard = (event) => {
  if (event?.uuid) {
    form.credit_card_uuid = event.uuid
    form.creditCard = event
    searchCreditCards(event.last_four_digits, form.creditCard, null, true)
  }
}
</script>
